const footer = {
  mode: "en",
  menu: {
    company: "Company",
    contact: "Contact",
    pricing: "Pricing",
    blog: "Blog",
    terms: "Terms of Service",
    privacy: "Privacy Policy",
  },
  conclusionTitle: "Streamline your document workflow",
  conclusionSubtitle: "Curious how TradDocs can help you? Let’s talk.",
  demo: "Start now for free",
  inquire: "Contact Us",
  luAddress1: "9 Av. des Hauts-Fourneaux, 4362 Esch-Belval Esch-sur-Alzette, Luxembourg",
  luAddress2: "Technoport",
  krAddress1: "122 Mapo-daero, Gongdeok-dong, Mapo-gu, Seoul",
  krAddress2: "11th Floor, FrontOne",
  krAddress3: "04213",
};

export default footer;
