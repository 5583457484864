import React from "react";
import { FaArrowRight, FaMapPin, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("footer");

  const servicesList = [
    {
      title: "TradDocs - For Traders",
      url: "/",
    },
    { title: "ParDocs - Document Parser", url: "https://www.pardocs.com" },
  ];

  const exploreList = [
    { title: t("menu.company"), url: "/company" },
    { title: t("menu.pricing"), url: "/pricing" },
    { title: t("menu.contact"), url: "/contact-us" },
    { title: t("menu.terms"), url: "/legal/terms" },
    { title: t("menu.privacy"), url: "/legal/privacy" },
  ];

  const ConclusionSection = () => (
    <div className="bg-gray-800 w-full py-6 md:py-12 flex justify-center text-gray-700">
      <div className="bg-gradient-to-br from-gray-700 to-gray-500 max-w-screen-xl w-full h-full flex flex-col items-center px-4 md:px-16 text-white py-32 rounded-lg">
        <p className="flex w-full justify-center font-medium text-2xl md:text-4xl break-keep text-center">
          {t("conclusionTitle")}
        </p>
        <p className="flex w-full justify-center text-base mt-8 text-center">{t("conclusionSubtitle")}</p>
        <div className="flex flex-col w-full md:flex-row justify-center space-y-3 md:space-y-0 items-center md:space-x-4 mt-12">
          <Link to="/auth/signup" className="w-full md:w-auto flex text-center justify-center">
            <button className="flex w-full md:w-auto items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full shadow md:text-base text-white bg-red-700 transition hover:brightness-110">
              <p>Start now, it's free</p>
              <FaArrowRight className="w-3 h-3" />
            </button>
          </Link>
          <Link to="/contact-us" className="w-full md:w-auto flex text-center justify-center">
            <button className="flex w-full md:w-auto items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full border md:text-base text-white transition hover:bg-gray-500 border-gray-500">
              Contact us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConclusionSection />
      <div className="w-full flex-shrink-0 flex flex-col items-center justify-center bg-gray-800 px-4">
        <div className="space-y-4 h-24 flex items-center w-full max-w-screen-xl border-b border-gray-600">
          <img
            src={"https://s3.traddocs.com/landing/logo_name_red.png"}
            alt=""
            className="h-5"
            draggable={false}
          />
        </div>
        <div
          style={{ minHeight: "24rem" }}
          className="max-w-screen-xl px-4 flex flex-col md:flex-row w-full h-full md:space-x-16 py-8"
        >
          <div style={{ minWidth: "16rem" }} className="text-sm text-gray-400 flex flex-col flex-shrink-0">
            <p className="text-gray-200 text-lg font-semibold mb-8">Services</p>
            <div className="space-y-4 flex flex-col">
              {servicesList.map((item) => (
                <Link key={item.title} to={item.url}>
                  <button>
                    <div className="text-base hover:text-white transition">{item.title}</div>
                  </button>
                </Link>
              ))}
            </div>
          </div>
          <div
            style={{ minWidth: "16rem" }}
            className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 flex-shrink-0"
          >
            <p className="text-gray-200 text-lg font-semibold mb-8">EXPLORE</p>
            <div className="space-y-4 flex flex-col">
              {exploreList.map((item) => (
                <Link key={item.title} to={item.url}>
                  <button>
                    <div className="text-base hover:text-white transition">{item.title}</div>
                  </button>
                </Link>
              ))}
            </div>
          </div>
          <div className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 flex-shrink-0">
            <p className="text-gray-200 text-lg font-semibold mb-8">CONTACT</p>
            <div className="flex space-x-3 mb-4">
              <FaMapPin className="mt-1" />
              <div>
                <p className="">{t("luAddress1")}</p>
                <p className="">{t("luAddress2")}</p>
              </div>
            </div>
            <div className="flex space-x-3 mb-4">
              <FaMapPin className="mt-1" />
              <div>
                <p className="">{t("krAddress1")}</p>
                <p className="">{t("krAddress2")}</p>
                <p className="">{t("krAddress3")}</p>
              </div>
            </div>
            <div className="flex space-x-3 mb-4">
              <FaPhoneAlt className=" mt-1" />
              <div>
                <p className="">+82 10-9114-0053</p>
              </div>
            </div>
            <div className="flex space-x-3 mb-4">
              <IoMdMail className=" mt-1" />
              <div>
                <p className="">info@traddocs.com</p>
              </div>
            </div>
            <div className="flex">
              <Link to="https://www.linkedin.com/company/traddocs/">
                <FaLinkedin className="w-8 h-8 text-white" />
              </Link>
            </div>
          </div>
          <div className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 justify-end w-full items-end">
            <img
              draggable={false}
              src={"https://s3.traddocs.com/landing/ms_dark.png"}
              alt=""
              className="w-48"
            />
          </div>
        </div>

        <div className="max-w-screen-xl w-full h-24 border-t border-gray-600 flex items-center justify-between text-gray-200 text-xs">
          <p className="">© {new Date().getFullYear()} TradDocs. All rights reserved.</p>
          <a href="https://www.vecteezy.com/free-png/3d-hologram">@ 3d Hologram PNGs by Vecteezy</a>
        </div>
      </div>
    </>
  );
};

export default Footer;
