import React, {useCallback, useEffect, useState} from "react";
import {LuLoader2, LuZoomIn} from "react-icons/lu";
import {Document, Page} from "react-pdf";
import {List} from "react-virtualized";
import {BsFiletypeDocx} from "react-icons/bs";
import {AiOutlineMail} from "react-icons/ai";

const PdfThumbnail = ({projectId, searchParams, splitterData, extensionType, setHighlightedIndices}) => {
    const [pageDimensions, setPageDimensions] = useState({width: null, height: null});
    const [scale, setScale] = useState(1);
    const [fileUrl, setFileUrl] = useState('');

    const documentIdPath = searchParams.get("document_id");
    const splitIdPath = parseInt(searchParams.get("split_id"));
    const documentTypePath = searchParams.get("doc_type");

    const pageNumber = splitterData[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices?.[0];

    useEffect(() => {
        documentIdPath && setFileUrl(`https://s3.traddocs.com/documents/${projectId}/source/${documentIdPath}.${extensionType}`);
    }, [documentIdPath, splitIdPath, documentTypePath, projectId, extensionType]);

    const onPageLoadSuccess = (page) => {
        const {width, height} = page.getViewport({scale: 1});
        const parentWidth = 100; // w-40
        const parentHeight = 100; // h-40

        const widthScale = parentWidth / width;
        const heightScale = parentHeight / height;
        const calculatedScale = Math.min(widthScale, heightScale);

        setScale(calculatedScale);
        setPageDimensions((prevDimensions) => {
            // Only update if dimensions have changed to avoid unnecessary re-renders
            if (prevDimensions.width !== width || prevDimensions.height !== height) {
                return {width, height};
            }
            return prevDimensions;
        });
    };

    const renderRow = useCallback(({index, key, style}) => {
        return (<div key={key} style={style}>
            <div className="pdf-page-compare">
                <Page
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                    className={"relative shadow border"}
                    loading={<LuLoader2 className="animate-spin w-10 h-10 text-white"/>}
                    scale={scale}
                ></Page>
            </div>
        </div>);
    }, [pageNumber, scale]);

    return (
        <div className="w-28 h-28 bg-gray-200 rounded flex justify-center items-center relative overflow-hidden">
            {["pdf", "jpg", "jpeg", "png"].includes(extensionType) && (
                <button
                    onClick={() => setHighlightedIndices([0])}
                    className="group absolute w-full h-full bg-black opacity-10 hover:opacity-40 transition z-20 flex items-center justify-center"
                >
                    <LuZoomIn className="text-white w-12 h-12 group-hover:flex hidden"/>
                </button>
            )}
            <div className="flex justify-center items-center">
                {extensionType === "docx" ? (
                    <div className="flex items-center w-full px-3 flex-col">
                        <BsFiletypeDocx className="text-gray-400 w-8 h-8"/>
                    </div>) : extensionType === "pdf" ? fileUrl && (
                    <Document
                        file={fileUrl}
                        onLoadError={(error) => console.error("Failed to load PDF file:", error)}
                        loading={<LuLoader2 className="animate-spin w-10 h-10 text-white"/>}
                        className={"flex justify-center items-center"}
                    >
                        <Page
                            className="hidden"
                            onLoadSuccess={onPageLoadSuccess}
                            pageNumber={splitterData[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices[0]}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        ></Page>
                        {pageDimensions.height && (
                            <div className="mx-auto">
                                <List
                                    width={pageDimensions.width * scale || 800}
                                    height={pageDimensions.height * scale || 600}
                                    rowCount={1}
                                    rowHeight={pageDimensions.height * scale}
                                    rowRenderer={renderRow}
                                />
                            </div>
                        )}
                    </Document>) : ['jpg', 'jpeg', 'png'].includes(extensionType) ? (
                    <img src={fileUrl} alt={''}/>) : ["msg", "eml"].includes(extensionType) ?
                    <div className="flex items-center w-full px-3 flex-col">
                        <AiOutlineMail className="text-gray-400 w-8 h-8"/>
                    </div> : <></>}
            </div>
        </div>
    );
};

export default PdfThumbnail;
