import React, { useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import TradeDocumentsTab from "./TradeDocumentsTab";
import FolderViewHeader from "./FolderViewHeader";
import useFetchJson from "../../hooks/useFetchJson";
import CrossCheckResult from "../../pages/DiscrepancyCheck/CrossCheckResult";
import CrossCheckContractResult from "../../pages/DiscrepancyCheck/CrossCheckContractResults";

const FolderView = () => {
  const { fetchJson, checkFileExistence } = useFetchJson();
  const fetchedSplitDocumentIds = useRef(new Set());
  const fetchedExtractDocumentIds = useRef(new Set());
  const fetchedComparedDocumentIds = useRef(new Set());
  const fetchedComparedInvoiceDocumentIds = useRef(new Set());
  const fetchedComparedLcDocumentIds = useRef(new Set());
  const fetchedComparedDiDocumentIds = useRef(new Set());
  const fetchedContractBlDocumentIds = useRef(new Set());
  const fetchedComparedContractsDocumentIds = useRef(new Set());
  const { projectId } = useParams();

  const [archiveInfo, setArchiveInfo] = useState(null);
  const [files, setFiles] = useState([]);

  const [splitterData, setSplitterData] = useState({});
  const [comparedData, setComparedData] = useState({});
  const [comparedInvoiceData, setComparedInvoiceData] = useState({});
  const [contractData, setContractData] = useState({});
  const [comparedLcData, setComparedLcData] = useState({});
  const [comparedDiData, setComparedDiData] = useState({});
  const [contractsData, setContractsData] = useState({});

  const [initialFetchProcessDone, setInitialFetchProcessDone] = useState(false);
  const [initialCompareProcessDone, setInitialCompareProcessDone] = useState(false);
  const [initiallyFetched, setInitiallyFetched] = useState(false);
  const [splitterFetched, setSplitterFetched] = useState(false);
  const [extractFetched, setExtractFetched] = useState(false);
  const [comparedFetched, setComparedFetched] = useState(false);
  const [filesFetched, setFilesFetched] = useState(false);

  const { getFiles, getArchive } = useFetchData(setFiles, setArchiveInfo, setFilesFetched);

  useEffect(() => {
    getFiles(projectId);
  }, [projectId, getFiles]);

  useEffect(() => {
    getArchive(projectId);
  }, [getArchive, projectId]);

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const fetchComparedDataOnce = useCallback(async () => {
    if (isEmptyObject(archiveInfo?.archive_compared_status)) {
      setComparedFetched(false);
      setInitialCompareProcessDone(true);
      if (files.length === 0 && filesFetched) {
        setSplitterFetched(true);
        setExtractFetched(true);
        setComparedFetched(true);
      }
      return;
    }
    const bolStatus = archiveInfo?.archive_compared_status?.bol || null;
    const invoiceStatus = archiveInfo?.archive_compared_status?.commercial_invoice || null;
    const contractStatus = archiveInfo?.archive_compared_status?.contract || null;
    const lcStatus = archiveInfo?.archive_compared_status?.letter_of_credit || null;
    const diStatus = archiveInfo?.archive_compared_status?.document_instruction || null;
    const contractsStatus = archiveInfo?.archive_compared_status?.contract_sales || null;

    const fetchStatusData = async (type, status, fetchedIds, setData) => {
      if (status) {
        const keysValues = Object.entries(status).map(([key, value]) => `${key}_${value}`);
        const fetchPromises = Object.entries(status).map(async ([key, value]) => {
          const kv = `${key}_${value}`;
          if (!fetchedIds.current.has(kv)) {
            await fetchJson(type, archiveInfo?.archive_id, kv, fetchedIds, setData);
            fetchedIds.current.add(kv); // Ensure the set is updated
          }
        });
        await Promise.all(fetchPromises);
        return keysValues.every((kv) => fetchedIds.current.has(kv));
      } else {
        return true;
      }
    };

    const allFetchedBol = await fetchStatusData("compared", bolStatus, fetchedComparedDocumentIds, setComparedData);
    const allFetchedInvoice = await fetchStatusData(
      "comparedInvoice",
      invoiceStatus,
      fetchedComparedInvoiceDocumentIds,
      setComparedInvoiceData
    );
    const allFetchedContract = await fetchStatusData(
      "contract",
      contractStatus,
      fetchedContractBlDocumentIds,
      setContractData
    );
    const allFetchedContracts = await fetchStatusData(
      "contractSales",
      contractsStatus,
      fetchedComparedContractsDocumentIds,
      setContractsData
    );
    const allFetchedLc = await fetchStatusData("lc", lcStatus, fetchedComparedLcDocumentIds, setComparedLcData);
    const allFetchedDi = await fetchStatusData("di", diStatus, fetchedComparedDiDocumentIds, setComparedDiData);

    if (
      allFetchedBol &&
      allFetchedInvoice &&
      allFetchedContract &&
      allFetchedContracts &&
      allFetchedLc &&
      allFetchedDi
    ) {
      setComparedFetched(true);
    }
    setInitialCompareProcessDone(true); // Set this after all fetches are done
  }, [fetchJson, files.length, archiveInfo, filesFetched]);

  const fetchSplitDataOnce = useCallback(async () => {
    const splitPromises = files.map((file) => {
      if (!fetchedSplitDocumentIds.current.has(file.document_id)) {
        return fetchJson("split", file.archive_id, file.document_id, fetchedSplitDocumentIds, setSplitterData);
      }
      return Promise.resolve();
    });

    await Promise.all(splitPromises);
    const allSplit = files.every((file) => fetchedSplitDocumentIds.current.has(file.document_id));

    if (allSplit) {
      setSplitterFetched(true);
      setInitiallyFetched(true);
      setInitialFetchProcessDone(true);
    } else {
      setInitiallyFetched(false);
      setInitialFetchProcessDone(true);
    }
  }, [files, fetchJson]);

  // INITIAL FETCH : INITIAL FETCH (COMPARE)
  useEffect(() => {
    if (archiveInfo && !initialFetchProcessDone && filesFetched) {
      fetchComparedDataOnce();
    }
  }, [archiveInfo, fetchJson, initialFetchProcessDone, files, fetchComparedDataOnce, filesFetched]);

  // INITIAL FETCH : INITIAL FETCH (SPLITTER & EXTRACT)
  useEffect(() => {
    if (initialCompareProcessDone && !initialFetchProcessDone) {
      if (comparedFetched) {
        fetchSplitDataOnce();
      } else {
        setInitialFetchProcessDone(true);
        setInitiallyFetched(false);
      }
    }
  }, [fetchSplitDataOnce, comparedFetched, files, initialCompareProcessDone, initialFetchProcessDone]);

  // 3 SECOND INTERVAL : SPLITTER FETCH
  useEffect(() => {
    if (initialFetchProcessDone && !comparedFetched && !splitterFetched) {
      const fetchSplitInterval = setInterval(() => {
        files.forEach((file) => {
          if (!fetchedSplitDocumentIds.current.has(file.document_id)) {
            fetchJson("split", file.archive_id, file.document_id, fetchedSplitDocumentIds, setSplitterData);
          }
        });
        const splitterCount = Object.keys(splitterData || {}).length;
        if (splitterCount === files.length) {
          setSplitterFetched(true);
          clearInterval(fetchSplitInterval);
          console.log("All SPLITTER data fetched, interval cleared");
        }
      }, 1000);
      return () => clearInterval(fetchSplitInterval);
    }
  }, [comparedFetched, fetchJson, files, initialFetchProcessDone, splitterData, splitterFetched]);

  // 3 SECOND INTERVAL : EXTRACTOR FETCH
  useEffect(() => {
    if (initialFetchProcessDone && !comparedFetched && splitterFetched && !extractFetched) {
      const fetchExtractInterval = setInterval(() => {
        files.forEach((file) => {
          if (!fetchedExtractDocumentIds.current.has(file.document_id)) {
            checkFileExistence("extracted", file.archive_id, file.document_id, fetchedExtractDocumentIds);
          }
        });
        const allFetched = files.every((file) => fetchedExtractDocumentIds.current.has(file.document_id));
        if (allFetched) {
          setExtractFetched(true);
          getArchive(projectId);
          clearInterval(fetchExtractInterval);
          console.log("All EXTRACTOR data fetched, interval cleared");
        }
      }, 1000);
      return () => clearInterval(fetchExtractInterval);
    }
  }, [
    checkFileExistence,
    comparedFetched,
    extractFetched,
    files,
    getArchive,
    initialFetchProcessDone,
    projectId,
    splitterFetched,
  ]);

  // COMPARE FETCH INTERVAL LOGIC
  useEffect(() => {
    if (initialFetchProcessDone && extractFetched && !comparedFetched) {
      const fetchComparedDataInterval = (archiveInfo) => {
        const fetchComparedInterval = setInterval(() => {
          const checkAllFetched = () => {
            const bolStatus = archiveInfo?.archive_compared_status?.bol || {};
            const invoiceStatus = archiveInfo?.archive_compared_status?.commercial_invoice || {};
            const contractStatus = archiveInfo?.archive_compared_status?.contract || {};
            const lcStatus = archiveInfo?.archive_compared_status?.letter_of_credit || {};
            const diStatus = archiveInfo?.archive_compared_status?.document_instruction || {};
            const contractsStatus = archiveInfo?.archive_compared_status?.contract_sales || {};

            const bolKeysValues = Object.entries(bolStatus).map(([key, value]) => `${key}_${value}`);
            const invoiceKeysValues = Object.entries(invoiceStatus).map(([key, value]) => `${key}_${value}`);
            const contractKeysValues = Object.entries(contractStatus).map(([key, value]) => `${key}_${value}`);
            const lcKeysValues = Object.entries(lcStatus).map(([key, value]) => `${key}_${value}`);
            const diKeysValues = Object.entries(diStatus).map(([key, value]) => `${key}_${value}`);
            const contractsKeysValues = Object.entries(contractsStatus).map(([key, value]) => `${key}_${value}`);

            const allFetchedBol = bolKeysValues.every((kv) => fetchedComparedDocumentIds.current.has(kv));
            const allFetchedInvoice = invoiceKeysValues.every((kv) =>
              fetchedComparedInvoiceDocumentIds.current.has(kv)
            );
            const allFetchedContract = contractKeysValues.every((kv) => fetchedContractBlDocumentIds.current.has(kv));
            const allFetchedLc = lcKeysValues.every((kv) => fetchedComparedLcDocumentIds.current.has(kv));
            const allFetchedDi = diKeysValues.every((kv) => fetchedComparedDiDocumentIds.current.has(kv));
            const allFetchedContracts = contractsKeysValues.every((kv) =>
              fetchedComparedContractsDocumentIds.current.has(kv)
            );

            return (
              allFetchedBol &&
              allFetchedInvoice &&
              allFetchedContract &&
              allFetchedLc &&
              allFetchedDi &&
              allFetchedContracts
            );
          };

          const fetchStatusData = (type, status, fetchedIds, setData) => {
            Object.entries(status).forEach(([key, value]) => {
              const kv = `${key}_${value}`;
              if (!fetchedIds.current.has(kv)) {
                fetchJson(type, archiveInfo?.archive_id, kv, fetchedIds, setData);
              }
            });
          };

          const bolStatus = archiveInfo?.archive_compared_status?.bol || null;
          const invoiceStatus = archiveInfo?.archive_compared_status?.commercial_invoice || null;
          const contractStatus = archiveInfo?.archive_compared_status?.contract || null;
          const lcStatus = archiveInfo?.archive_compared_status?.letter_of_credit || null;
          const diStatus = archiveInfo?.archive_compared_status?.document_instruction || null;
          const contractsStatus = archiveInfo?.archive_compared_status?.contract_sales || null;

          if (bolStatus) {
            fetchStatusData("compared", bolStatus, fetchedComparedDocumentIds, setComparedData);
          }

          if (invoiceStatus) {
            fetchStatusData(
              "comparedInvoice",
              invoiceStatus,
              fetchedComparedInvoiceDocumentIds,
              setComparedInvoiceData
            );
          }

          if (contractStatus) {
            fetchStatusData("contract", contractStatus, fetchedContractBlDocumentIds, setContractData);
          }

          if (lcStatus) {
            fetchStatusData("lc", lcStatus, fetchedComparedLcDocumentIds, setComparedLcData);
          }

          if (diStatus) {
            fetchStatusData("di", diStatus, fetchedComparedDiDocumentIds, setComparedDiData);
          }

          if (contractsStatus) {
            fetchStatusData("contractSales", contractsStatus, fetchedComparedContractsDocumentIds, setContractsData);
          }

          if (checkAllFetched()) {
            setComparedFetched(true);
            clearInterval(fetchComparedInterval);
          }
        }, 1000);

        return () => clearInterval(fetchComparedInterval);
      };

      return fetchComparedDataInterval(archiveInfo);
    }
  }, [
    archiveInfo,
    fetchJson,
    extractFetched,
    initialFetchProcessDone,
    initiallyFetched,
    comparedFetched,
    fetchedComparedDocumentIds,
    fetchedComparedInvoiceDocumentIds,
    fetchedContractBlDocumentIds,
  ]);

  const FolderDashboard = () => (
    <div className="w-full h-full bg-gray-100 flex flex-col items-start rounded overflow-y-auto">
      <FolderViewHeader
        archiveInfo={archiveInfo}
        projectId={projectId}
        getFiles={getFiles}
        getArchive={getArchive}
        setExtractFetched={setExtractFetched}
        setSplitterFetched={setSplitterFetched}
        setComparedFetched={setComparedFetched}
        setInitiallyFetched={setInitiallyFetched}
      />
      <div className="bg-gray-100 w-full h-full p-3 space-y-3">
        <TradeDocumentsTab
          projectId={projectId}
          archiveInfo={archiveInfo}
          files={files}
          splitterData={splitterData}
          comparedData={comparedData}
          comparedInvoiceData={comparedInvoiceData}
          comparedLcData={comparedLcData}
          comparedDiData={comparedDiData}
          contractData={contractData}
          contractsData={contractsData}
          comparedFetched={comparedFetched}
          initiallyFetched={initiallyFetched}
          splitterFetched={splitterFetched}
          getArchive={getArchive}
          getFiles={getFiles}
          setExtractFetched={setExtractFetched}
          setSplitterFetched={setSplitterFetched}
          setComparedFetched={setComparedFetched}
          setInitiallyFetched={setInitiallyFetched}
        />
      </div>
    </div>
  );

  if (initialFetchProcessDone)
    return (
      <div className="w-full h-full overflow-y-auto flex justify-center bg-gray-100 text-gray-700">
        <div className="w-full flex flex-col space-y-3">
          <div className="flex h-full">
            <Routes>
              <Route path="/check-results/*" element={<CrossCheckResult />} />
              <Route path="/check-contract-results/*" element={<CrossCheckContractResult />} />
              <Route path="/*" element={<FolderDashboard />} />
            </Routes>
          </div>
        </div>
      </div>
    );
};

export default FolderView;
